import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/Layout"
import Section from "../components/Section/Section"
import SEO from "../components/Seo"
import { PageType } from ".."

const NotFoundPage: PageType = ({ location }) => (
    <Layout location={location}>
        <Section>
            <SEO title="404: Not found" />
            <h1>Uuups &ndash; Seitenfehler</h1>
            <p>
                Sieht so aus, als ob die Seite leider nicht gefunden werden
                konnte.
            </p>
            <br />
            <p>
                Hier kommen Sie zur <Link to="/">Startseite</Link> zurück.
            </p>
            <br />
        </Section>
    </Layout>
)

export default NotFoundPage
